import React, { useEffect } from "react";
import Layout from "layout/opportunitiesLayout";
import { useState } from "react";
import { fetchData, fetchFilters } from "../utils/requests";
import { Pagination, TopScrollBtn } from "components";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router";
import useFilter from "../hooks/useFilter";
import {
  Table,
  TableContainer,
  TableWrapper,
  Tbody,
  TdDiv,
  ThDiv,
  Thead,
  ViewResultButton,
} from "./style.opportunities";
import { Link } from "react-router-dom";

function Opportunities(props) {
  const SEARCHBAR_PLACEHOLDER = "Search by Opportunities name or Keyword";
  const TABLE_PAGE_RECORD_COUNT = 40;
  const TABLE_HEADERS = [
    "ID",
    "Title",
    "Source",
    "Funding Agency",
    "Issued Date",
    "WISE-R Match",
  ];
  const TABLE_COLUMN_WIDTHS = ["11%", "40%", "16%", "11%", "11%", "11%"];

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const { currentUser, userLogOut } = useAuth();
  const [searchText, setSearchText] = useState("");

  const [data, setData] = useState({
    currentPage: 1,
    pageCount: 1,
    list: [],
  });

  const {
    filters,
    setFilterByKey,
    resetFilters,
    options,
    setOptionsWithResults,
  } = useFilter();

  useEffect(() => {
    queryFilteredData();
  }, [filters]);

  const queryFilteredData = (newPage = data.currentPage) => {
    const pageToQuery = newPage == data.currentPage ? 1 : newPage;
    setLoading(true);

    // const filterJoined = {
    //     Disease: [...filtersSelected.Disease.slice(0), location.state],
    //     Sponsor: [...filtersSelected.Sponsor.map((e) => e.replace("'", "%27"))],
    //     Location: [
    //         ...new Set([
    //             ...filtersSelected.Locations,
    //             ...filtersSelected.SurroundCountries,
    //         ]),
    //     ],
    //     Phase: [...filtersSelected.Phase],
    //     Active: [...filtersSelected.Active],
    //     StartYear: [...filtersSelected.Year_Started],
    //     StudyType: [
    //         ...filtersSelected.Study_Type.map((e) => e.replace("'", "%27")),
    //     ],
    //     CompletionYear: [...filtersSelected.Year_Completed],
    // };

    let filtersData = JSON.parse(JSON.stringify(filters));
    filtersData["Search"] = searchText;

    const dataString = JSON.stringify({
      filters: filtersData,
      pageNum: pageToQuery,
    });

    fetchFilters(dataString, currentUser, userLogOut)
      .then((results) => {
        setOptionsWithResults(results);
      })
      .catch((err) =>
        console.error(`Error Occurred during fetch: ${err.message}`)
      )
      .finally(() => setLoading(false));

    fetchData(dataString, currentUser, userLogOut)
      .then((results) => {
        console.log(results.list);
        setData((prevData) => ({
          ...prevData,
          currentPage: pageToQuery,
          pageCount: results.totalPageCount,
          // countOfFilteredProjects: results.totalCount,
          list: [
            {
              ID: "RFA-NS-23-001",
              Title:
                "HEAL Initiative: Development of Therapies and Technologies Directed at Enhanced Pain Management (R41/R42 Clinical Trial Not Allowed)",
              Source: "https://www.google.com",
              Funding_Agency: "NIH",
              Issued_Date: "09/12/2015",
            },
            {
              ID: "RFA-NS-23-002",
              Title:
                "HEAL Initiative: Development of Therapies and Technologies Directed at Enhanced Pain Management (R41/R42 Clinical Trial Not Allowed)",
              Source: "https://www.google.com",
              Funding_Agency: "NIH",
              Issued_Date: "09/12/2015",
            },
            {
              ID: "RFA-NS-23-003",
              Title:
                "HEAL Initiative: Development of Therapies and Technologies Directed at Enhanced Pain Management (R41/R42 Clinical Trial Not Allowed)",
              Source: "https://www.google.com",
              Funding_Agency: "NIH",
              Issued_Date: "09/12/2015",
            },
            {
              ID: "RFA-NS-23-004",
              Title:
                "HEAL Initiative: Development of Therapies and Technologies Directed at Enhanced Pain Management (R41/R42 Clinical Trial Not Allowed)",
              Source: "https://www.google.com",
              Funding_Agency: "NIH",
              Issued_Date: "09/12/2015",
            },
            {
              ID: "RFA-NS-23-005",
              Title:
                "HEAL Initiative: Development of Therapies and Technologies Directed at Enhanced Pain Management (R41/R42 Clinical Trial Not Allowed)",
              Source: "https://www.google.com",
              Funding_Agency: "NIH",
              Issued_Date: "09/12/2015",
            },
            {
              ID: "RFA-NS-23-06",
              Title:
                "HEAL Initiative: Development of Therapies and Technologies Directed at Enhanced Pain Management (R41/R42 Clinical Trial Not Allowed)",
              Source: "https://www.google.com",
              Funding_Agency: "NIH",
              Issued_Date: "09/12/2015",
            },
            {
              ID: "RFA-NS-23-007",
              Title:
                "HEAL Initiative: Development of Therapies and Technologies Directed at Enhanced Pain Management (R41/R42 Clinical Trial Not Allowed)",
              Source: "https://www.google.com",
              Funding_Agency: "NIH",
              Issued_Date: "09/12/2015",
            },
            {
              ID: "RFA-NS-23-008",
              Title:
                "HEAL Initiative: Development of Therapies and Technologies Directed at Enhanced Pain Management (R41/R42 Clinical Trial Not Allowed)",
              Source: "https://www.google.com",
              Funding_Agency: "NIH",
              Issued_Date: "09/12/2015",
            },
            {
              ID: "RFA-NS-23-009",
              Title:
                "HEAL Initiative: Development of Therapies and Technologies Directed at Enhanced Pain Management (R41/R42 Clinical Trial Not Allowed)",
              Source: "https://www.google.com",
              Funding_Agency: "NIH",
              Issued_Date: "09/12/2015",
            },
            {
              ID: "RFA-NS-23-010",
              Title:
                "HEAL Initiative: Development of Therapies and Technologies Directed at Enhanced Pain Management (R41/R42 Clinical Trial Not Allowed)",
              Source: "https://www.google.com",
              Funding_Agency: "NIH",
              Issued_Date: "09/12/2015",
            },
          ],
        }));
      })
      .catch((err) =>
        console.error(`Error Occurred during fetch: ${err.message}`)
      )
      .finally(() => setLoading(false));
  };

  const paginate = (number) => {
    queryFilteredData(number);
    scrollToListContent();
  };

  const scrollToListContent = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSearchBarFocusCallback = (e) => {
    window.scrollTo(0, 0);
  };

  const handleBackBtnSubmit = () => {
    navigate(-1);
  };

  const FirstRecordOnPage =
    (data.currentPage - 1) * TABLE_PAGE_RECORD_COUNT + 1;
  const LastRecordOnPage = data.currentPage * TABLE_PAGE_RECORD_COUNT;
  const TotalResults = data.pageCount * TABLE_PAGE_RECORD_COUNT;

  return (
    <Layout
      isLoaderDisplay={isLoading}
      footerRelative={true}
      searchbarPlaceholder={SEARCHBAR_PLACEHOLDER}
      handleSearchBarFocusCallback={handleSearchBarFocusCallback}
      handleSearchSubmit={queryFilteredData}
      handleSearchInput={(e) => setSearchText(e.target.value)}
      onBackClickCallback={handleBackBtnSubmit}
      filterOptions={options}
      filtersSelected={filters}
      handleClickFilters={setFilterByKey}
      handleResetFilters={resetFilters}
    >
      {data.pageCount > 0 && (
        <TopScrollBtn
          color="#2D6291"
          label={"To Top"}
          handleOnClick={scrollToListContent}
        />
      )}
      {data.list.length > 0 ? (
        <>
          <TableContainer>
            <div style={{ width: "-webkit-fill-available", marginLeft: "5%" }}>
              {`Showing ${FirstRecordOnPage} - ${LastRecordOnPage} of ${TotalResults} results`}
            </div>
            <TableComponent
              tableHeaders={TABLE_HEADERS}
              columnWidths={TABLE_COLUMN_WIDTHS}
              tableData={data.list}
            />
          </TableContainer>
          <Pagination
            numberOfPages={data.pageCount}
            currentPage={data.currentPage}
            paginate={paginate}
          />
        </>
      ) : (
        <h1 style={{ color: "#827988", width: "100%", textAlign: "center" }}>
          No data Present at the moment
        </h1>
      )}
    </Layout>
  );
}

const TableComponent = ({ tableHeaders, columnWidths, tableData }) => {
  return (
    <TableWrapper>
      <Table>
        <Thead>
          <tr>
            {tableHeaders.map((header, index) => {
              return (
                <th width={columnWidths[index]}>
                  <ThDiv>
                    <span>{header}</span>
                  </ThDiv>
                </th>
              );
            })}
          </tr>
        </Thead>
        <Tbody>
          <TableBody tableData={tableData} />
        </Tbody>
      </Table>
    </TableWrapper>
  );
};

const TableBody = ({ tableData }) => {
  const navigate = useNavigate();

  return tableData.length > 0 ? (
    tableData.map((data, index) => {
      return (
        <tr key={data._id}>
          <td>
            <TdDiv>{data["ID"]}</TdDiv>
          </td>
          <td>{data["Title"]}</td>

          <td>
            <TdDiv>
              <Link
                to={data["Source"]}
                target="_blank"
                style={{ color: "#4A6697" }}
              >
                View Source Here
              </Link>
            </TdDiv>
          </td>

          <td>
            <TdDiv>{data["Funding_Agency"]}</TdDiv>
          </td>
          <td>
            <TdDiv>{data["Issued_Date"]}</TdDiv>
          </td>

          <td style={{ whiteSpace: "nowrap" }}>
            <TdDiv>
              <ViewResultButton
                onClick={() => navigate(`${data["ID"]}/WiserMatches`)}
              >
                View Matches
              </ViewResultButton>
            </TdDiv>
          </td>
        </tr>
      );
    })
  ) : (
    <></>
  );
};

export default Opportunities;
