import React, { useEffect } from "react";
import Layout from "layout/opportunitiesLayout";
import { useState } from "react";
import { fetchData, fetchFilters } from "../utils/requests";
import { Pagination, TopScrollBtn } from "components";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router";
import useFilter from "../hooks/useFilter";
import { CardContainer } from "./style.wisermatches";
import OrganizationCard from "../components/OrganizationCard";
import { ContentContainer } from "../style";

function WiserMatches(props) {
    const SEARCHBAR_PLACEHOLDER = "Search by Organizations name or Keyword";
    const LIST_PAGE_RECORD_COUNT = 20;

    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const { currentUser, userLogOut } = useAuth();
    const [searchText, setSearchText] = useState("");

    const [data, setData] = useState({
        currentPage: 1,
        pageCount: 1,
        list: [],
    });

    const {
        filters,
        setFilterByKey,
        resetFilters,
        options,
        setOptionsWithResults,
    } = useFilter('Wiser Matches');

    useEffect(() => {
        queryFilteredData();
    }, [filters]);

    const queryFilteredData = (newPage = data.currentPage) => {
        const pageToQuery = newPage == data.currentPage ? 1 : newPage;
        setLoading(true);

        let filtersData = JSON.parse(JSON.stringify(filters));
        filtersData["Search"] = searchText;

        const dataString = JSON.stringify({
        filters: filtersData,
        pageNum: pageToQuery,
        });

        fetchFilters(dataString, currentUser, userLogOut)
        .then((results) => {
            results['Organization_Type']=[['NIH', true]];
            setOptionsWithResults(results);
        })
        .catch((err) =>
            console.error(`Error Occurred during fetch: ${err.message}`)
        )
        .finally(() => setLoading(false));

        fetchData(dataString, currentUser, userLogOut)
        .then((results) => {
            console.log(results.list);
            setData((prevData) => ({
            ...prevData,
            currentPage: pageToQuery,
            pageCount: results.totalPageCount,
            // countOfFilteredProjects: results.totalCount,
            list:[
                {
                    Company_Name: 'Sanofi',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'As a health journey partner, we, at Sanofi, are proud that our vaccines can help to protect us and our loved ones from a wide range of severe infectious diseases, at every stage of life. Sanofi Pasteur, our vaccines global business unit, partners with the our vaccines global business unit, partners with the ...',
                    Locations: ['Washington', 'New York'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Holmusk (KKT Technology Pte Ltd)',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a urine-based assay for early pregnancy detection...',
                    Locations: ['Washington', 'New York','1 more'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Adipomics',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a urine-based assay for early pregnancy detection...',
                    Locations: ['Washington', 'New York','1 more'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Congruence Medical Solutions, LLC',
                    Pharmaceuticals: ['Other'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a ...',
                    Locations: ['Washington', 'New York'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Sanofi',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'As a health journey partner, we, at Sanofi, are proud that our vaccines can help to protect us and our loved ones from a wide range of severe infectious diseases, at every stage of life. Sanofi Pasteur, our vaccines global business unit, partners with the our vaccines global business unit, partners with the ...',
                    Locations: ['Washington', 'New York'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Holmusk (KKT Technology Pte Ltd)',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a urine-based assay for early pregnancy detection...',
                    Locations: ['Washington', 'New York','1 more'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Adipomics',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a urine-based assay for early pregnancy detection...',
                    Locations: ['Washington', 'New York','1 more'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Congruence Medical Solutions, LLC',
                    Pharmaceuticals: ['Other'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a ...',
                    Locations: ['Washington', 'New York'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Sanofi',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'As a health journey partner, we, at Sanofi, are proud that our vaccines can help to protect us and our loved ones from a wide range of severe infectious diseases, at every stage of life. Sanofi Pasteur, our vaccines global business unit, partners with the our vaccines global business unit, partners with the ...',
                    Locations: ['Washington', 'New York'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Holmusk (KKT Technology Pte Ltd)',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a urine-based assay for early pregnancy detection...',
                    Locations: ['Washington', 'New York','1 more'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Adipomics',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a urine-based assay for early pregnancy detection...',
                    Locations: ['Washington', 'New York','1 more'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Congruence Medical Solutions, LLC',
                    Pharmaceuticals: ['Other'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a ...',
                    Locations: ['Washington', 'New York'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Sanofi',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'As a health journey partner, we, at Sanofi, are proud that our vaccines can help to protect us and our loved ones from a wide range of severe infectious diseases, at every stage of life. Sanofi Pasteur, our vaccines global business unit, partners with the our vaccines global business unit, partners with the ...',
                    Locations: ['Washington', 'New York'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Holmusk (KKT Technology Pte Ltd)',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a urine-based assay for early pregnancy detection...',
                    Locations: ['Washington', 'New York','1 more'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Adipomics',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a urine-based assay for early pregnancy detection...',
                    Locations: ['Washington', 'New York','1 more'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Congruence Medical Solutions, LLC',
                    Pharmaceuticals: ['Other'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a ...',
                    Locations: ['Washington', 'New York'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Sanofi',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'As a health journey partner, we, at Sanofi, are proud that our vaccines can help to protect us and our loved ones from a wide range of severe infectious diseases, at every stage of life. Sanofi Pasteur, our vaccines global business unit, partners with the our vaccines global business unit, partners with the ...',
                    Locations: ['Washington', 'New York'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Holmusk (KKT Technology Pte Ltd)',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a urine-based assay for early pregnancy detection...',
                    Locations: ['Washington', 'New York','1 more'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Adipomics',
                    Pharmaceuticals: ['Cardiovascular & Metabolism'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a urine-based assay for early pregnancy detection...',
                    Locations: ['Washington', 'New York','1 more'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                },
                {
                    Company_Name: 'Congruence Medical Solutions, LLC',
                    Pharmaceuticals: ['Other'],
                    Description: 'Adipose-based diagnostic and therapeutic platform. We developed a proprietary methodology to isolate adipocyte-derived exosomes from blood and urine. Our initial diagnostic focus is on a ...',
                    Locations: ['Washington', 'New York'],
                    Employee_Size: '12,000',
                    Total_Funding: 'N/A',
                    Count_of_Publications: 'N/A'
                }
            ]
            }));
        })
        .catch((err) =>
            console.error(`Error Occurred during fetch: ${err.message}`)
        )
        .finally(() => setLoading(false));
    };

    const paginate = (number) => {
        queryFilteredData(number);
        scrollToListContent();
    };

    const scrollToListContent = () => {
        window.scrollTo({
        top: 0,
        behavior: "smooth",
        });
    };

    const handleSearchBarFocusCallback = (e) => {
        window.scrollTo(0, 0);
    };

    const handleBackBtnSubmit = () => {
        navigate(-1);
    };
    
    const FirstRecordOnPage = (data.currentPage - 1) * LIST_PAGE_RECORD_COUNT + 1;
    const LastRecordOnPage = data.currentPage * LIST_PAGE_RECORD_COUNT;
    const TotalResults = data.pageCount * LIST_PAGE_RECORD_COUNT;

  return (
    <Layout
        title={'WISE-R Matches'}
        isLoaderDisplay={isLoading}
        footerRelative={true}
        searchbarPlaceholder={SEARCHBAR_PLACEHOLDER}
        handleSearchBarFocusCallback={handleSearchBarFocusCallback}
        handleSearchSubmit={queryFilteredData}
        handleSearchInput={(e) => setSearchText(e.target.value)}
        onBackClickCallback={handleBackBtnSubmit}
        filterOptions={options}
        filtersSelected={filters}
        handleClickFilters={setFilterByKey}
        handleResetFilters={resetFilters}
    >
      
        <ContentContainer
            margin={'50px auto 0'}>
            {data.pageCount > 0 && (
                <TopScrollBtn
                color="#2D6291"
                label={"To Top"}
                handleOnClick={scrollToListContent}
                />
            )}
            <div>
                {`Showing ${FirstRecordOnPage} - ${LastRecordOnPage} of ${TotalResults} results`}
            </div>
            <CardContainer>
                { data.list.length > 0  
                    ? data.list.map((item, index) => OrganizationCard(item, index)) 
                    : (
                        <h1 style={{color: '#827988', width: '100%', textAlign: 'center'}}>
                            No data Present at the moment
                        </h1>
                    ) }
            </CardContainer>
            <Pagination
            numberOfPages={data.pageCount}
            currentPage={data.currentPage}
            paginate={paginate}
            />
        </ContentContainer>
    </Layout>
  );
}

export default WiserMatches;
