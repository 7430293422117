import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Layout from "../../layout";
import ClinicalTrailGraphUI from "./Graph/UI";
import ClinicalTrialListUI from "./List/UI";
import { useNavigate } from "react-router";
import { TopScrollBtn } from "../../components";
import { getEncryptObj } from "../../services/Utils/encrypt.js";
import { getAPIParams } from "middleware/api.js";
import { useAuth } from "../../contexts/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { filterActions as ClinicalActions } from "../../store/ClinicalTrials/filtersReducer";
import { updateOptions } from "../../store/ClinicalTrials/optionsReducer";

function ClinicalTrials() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { currentUser, userLogOut } = useAuth();
  const [currentPage, SetCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [ifSelected, setIfSelected] = useState(false);
  const [isGraphSectionVisible, setIsGraphSectionVisible] = useState(true);

  const filtersOption = useSelector((store) => store.clinicalOptions.options);
  const filtersSelected = useSelector((store) => store.clinicalFilter.filters);

  const [data, setData] = useState({
    searchText: "",
    currentPage: 1,
    countOfTotalTrials: 0,
    countOfFilteredTrials: 0,
    bySponsor: [],
    bySponsorCategory: [],
    map: [],
    byAge: {},
    bySampleSize: {},
    byPhase: {},
    byStartYear: {},
    pageCount: 1,
    list: [],
    children: {},
    //TODO: Remove below when fetching data from backend.
    stats: [],
  });

  const navigate = useNavigate();
  const listContentRef = useRef();
  const params = useParams();

  const TABLE_CONTENT = 10;
  const pageCountofTable = Math.ceil(data.bySponsor.length / TABLE_CONTENT);

  const queryFilteredData = (newPage = data.currentPage) => {
    const [MODE, PATH, PORT] = getAPIParams("CT");

    const pageToQuery = newPage == data.currentPage ? 1 : newPage;

    setLoading(true);

    const filterJoined = {
      Disease: [...filtersSelected.Disease.slice(0), location.state],
      Sponsor: [...filtersSelected.Sponsor.map((e) => e.replace("'", "%27"))],
      Location: [
        ...new Set([
          ...filtersSelected.Locations,
          ...filtersSelected.SurroundCountries,
        ]),
      ],
      Phase: [...filtersSelected.Phase],
      Active: [...filtersSelected.Active],
      StartYear: [...filtersSelected.Year_Started],
      StudyType: [
        ...filtersSelected.Study_Type.map((e) => e.replace("'", "%27")),
      ],
      CompletionYear: [...filtersSelected.Year_Completed],
    };

    let filtersData = JSON.parse(JSON.stringify(filterJoined));
    filtersData["Search"] = data.searchText;
    const dataString = JSON.stringify({
      filters: filtersData,
      pageNum: pageToQuery,
    });

    const fetchFilters = async () => {
      const encryptObj = await getEncryptObj(dataString);
      const rawResponse = await fetch(
        `${MODE}://${PATH}:${PORT}/v1/clinicalTrials/filter?iv=${encryptObj["iv"]}&encryptedData=${encryptObj["encryptedData"]}`,
        {
          method: "GET",
          headers: {
            Authorization: `${currentUser.token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!rawResponse.ok && rawResponse.status === 403) {
        userLogOut();
      }

      const results = await rawResponse.json();
      // console.log(results);
      Object.entries(results.filterResults).map((filterVal) => {
        dispatch(
          updateOptions({
            optionKey: filterVal[0],
            optionVal: filterVal[1],
          })
        );
      });
    };

    const fetchData = async () => {
      const encryptObj = await getEncryptObj(dataString);

      const rawResponse = await fetch(
        `${MODE}://${PATH}:${PORT}/v1/clinicalTrials?iv=${encryptObj["iv"]}&encryptedData=${encryptObj["encryptedData"]}`,
        {
          method: "GET",
          headers: {
            Authorization: `${currentUser.token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!rawResponse.ok && rawResponse.status === 403) {
        userLogOut();
      }

      // Receive filtered results
      const results = await rawResponse.json();
      console.log(results);

      setData((prevData) => ({
        ...prevData,
        countOfTotalTrials: results.data.countOfTotalTrials,
        countOfFilteredTrials: results.data.countOfFilteredTrials,
        bySponsor: results.data.bySponsor,
        bySponsorCategory: results.data.bySponsorCategory.slice(0, 8),
        map: results.data.mapData,
        byAge: results.data.byAge,
        bySampleSize: results.data.bySampleSize,
        byPhase: results.data.byPhase,
        byStartYear: results.data.byStartYear,
        pageCount: results.pageCount,
        list: results.list,
        children: results.data.childData,
        currentPage: pageToQuery,
        stats: [
          [results.stats.totalCount, "Clinical Trials"],
          [results.stats.activeCount, "Active Trials"],
          [results.stats.phaseOneCount, "Phase 1 Trials"],
          [results.stats.phaseTwoCount, "Phase 2 Trials"],
          [results.stats.phaseThreeCount, "Phase 3 Trials"],
        ],
      }));
      setLoading(false);
    };

    fetchFilters().catch((err) =>
      console.error(`Error Occurred during fetch: ${err.message}`)
    );
    fetchData().catch((err) =>
      console.error(`Error Occurred during fetch: ${err.message}`)
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (filtersSelected.Disease.length == 0) {
      handleClickFilters("Disease", [params.disease]);
      return;
    }
    setIfSelected(true);
    fetchCall();
  }, [filtersSelected]);

  const handleClickFilters = (key1, val) => {
    dispatch(ClinicalActions.changeFilter({ key: key1, value: val }));
  };

  const handleResetFilters = () => {
    dispatch(ClinicalActions.setDefault());
  };

  const handleSearchInput = (e) => {
    setData({ ...data, searchText: e.target.value });
  };

  const handleSearchSubmit = () => {
    queryFilteredData();
  };

  const handleSearchBarFocusCallback = (e) => {
    setIsGraphSectionVisible(false);
    window.scrollTo(0, 0);
  };

  const handleBackBtnSubmit = () => {
    if (isGraphSectionVisible) {
      navigate(-1);
    } else {
      setIsGraphSectionVisible(true);
    }
  };

  const fetchCall = () => {
    queryFilteredData();
    setIfSelected(false);
  };

  const paginate = (number) => {
    queryFilteredData(number);

    listContentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const scrollToListContent = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Layout
      isLoaderDisplay={isLoading}
      title={"Clinical Trials"}
      footerRelative={true}
      handleSearchSubmit={handleSearchSubmit}
      handleSearchInput={handleSearchInput}
      handleSearchBarFocusCallback={handleSearchBarFocusCallback}
      isGraphSectionVisible={isGraphSectionVisible}
      onBackClickCallback={handleBackBtnSubmit}
      filterOptions={filtersOption}
      filtersSelected={filtersSelected}
      handleClickFilters={handleClickFilters}
      handleResetFilters={handleResetFilters}
      singleSelect={["Active", "Disease"]}
      showLastDataRefreshDate={true}
    >
      <ClinicalTrailGraphUI
        data={data}
        chartDisease={filtersSelected["Disease"]}
        isVisible={isGraphSectionVisible}
        currentPage={currentPage}
        pageCountofTable={pageCountofTable}
        handlePageClick={(value) => SetCurrentPage(value)}
      />

      {data.pageCount > 0 && (
        <TopScrollBtn
          color="#2D6291"
          label={"To Top"}
          handleOnClick={scrollToListContent}
        />
      )}

      <ClinicalTrialListUI
        data={data}
        disease={params.disease}
        locationState={location.state}
        areTabsVisible={!isGraphSectionVisible}
        innerRef={listContentRef}
        paginate={paginate}
      />
    </Layout>
  );
}

export default ClinicalTrials;
