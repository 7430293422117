import { TagsContainer, TagsContent, TagsHeading, TagsContentShort } from './style.tags';

const Tags = ({data, tagsKey=""}) => {
    // console.log(color)
    return (
        data !== null && data !== undefined
            ? data.length > 40 ?
                <TagsContainer>
                    {
                        tagsKey && (
                            <TagsHeading>{tagsKey.split('_').join(' ')}</TagsHeading>
                        )
                    }
                    <TagsContent data-tooltip={data}>{data.slice(0, 25) + '...'}</TagsContent>
                </TagsContainer>
                :
                <TagsContainer>
                    {
                        tagsKey && (
                            <TagsHeading>{tagsKey.split('_').join(' ')}</TagsHeading>
                        )
                    }
                    <TagsContentShort>{data}</TagsContentShort>
                </TagsContainer>
            : null
    );
}

export default Tags;