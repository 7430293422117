import React, { useRef } from "react";
import { ChartContainer } from "./style.charts";
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut, getElementsAtEvent } from "react-chartjs-2";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { numFormatter } from "../../services/Function/number";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { byPhaseColorArr } from "../../services/Constants/constant";

const DoughnutChart = ({
  data,
  label,
  chartHeight,
  chartWidth,
  style,
  color,
  clickHandler
}) => {
  ChartJS.register(ChartDataLabels);
  const { height, width } = useWindowDimensions();
  const chartRef = useRef();

  const doughnutData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: `${label ? label : "value"}`,
        data: Object.values(data),
        backgroundColor: color ? color : byPhaseColorArr,
        borderColor: color ? color : byPhaseColorArr,
      },
    ],
  };

  const resizeFont = (height, width) => {
    if (height >= 2310 && width >= 3350) return 18;
    if (height >= 1210 && width >= 1800) return 10;
    if (height >= 920 && width >= 1380) return 7;
    if (height >= 720 && width >= 1024) return 6;
    if (height >= 620 && width >= 768) return 5;
    return 4;
  };

  const resizeLegend = (height, width) => {
    if (height >= 2310 && width >= 3350) return 40;
    if (height >= 1210 && width >= 1800) return 20;
    if (height >= 920 && width >= 1380) return 10;
    if (height >= 720 && width >= 1024) return 9;
    if (height >= 620 && width >= 768) return 8;
    return 6;
  };

  const plugins = [
    {
      afterDraw: function (chart) {
        let width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        let fontSize = (height / 180).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        let text = "$ " + numFormatter(chart.config.options.centerText.text),
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height * 0.48;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const handleClick = (event) => {
    const index = getElementsAtEvent(chartRef.current, event)[0].index;
    const label = Object.keys(data)[index];
    clickHandler(label);
  }

  return (
    <ChartContainer width={chartWidth} height={chartHeight} style={style}>
      <Doughnut
        ref={chartRef}
        data={doughnutData}
        onClick={(event) => handleClick(event)}
        options={{
          centerText: {
            display: false,
          },
          indexAxis: "y",
          tooltips: {
            mode: "index",
            intersect: false,
          },
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },
          // onClick:(event, el) => {
          //     if (el && clickable) {
          //         const label = data["labels"][[el[0]["index"]]];
          //         if (label !== "Not available") {
          //             handleClick(label);
          //         }
          //     }
          // },
          // onHover: (event, el) => {
          //     if (clickable) {
          //         const target = event.native ? event.native.target : event.target;
          //         target.style.cursor = el[0] ? 'pointer' : 'default';
          //     }
          // },
          elements: {
            arc: {
              borderWidth: 0,
              borderRadius: 4,
              spacing: 3,
            },
          },
          layout: {
            padding: 5,
          },
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: `right`,
              align: "end",
              labels: {
                fontSize: resizeFont(height, width),
                boxWidth: resizeLegend(height, width),
                fontColor: "#000000",
                pointStyle: "rectRounded",
                padding: 8,
                usePointStyle: true,
              },
            },
            datalabels: {
              display: false,
            },
          },
        }}
      />
    </ChartContainer>
  );
};

export default DoughnutChart;
