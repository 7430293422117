import styled from "styled-components";
import { media } from "assets/static";
import { BoxedButton } from "components";

export const OrganisationCard = styled.div`
  flex: 0 0 25vw;
  max-width: 25vw;
  
  height: max-content;
  padding: 1.5%;
  box-sizing: border-box;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(196, 196, 196, 0.2);
  border-radius: 24px;

  ${media.monitor} {
    border-radius: 24px;
  }

  ${media.desktop} {
    flex: 0 0 21vw;
    max-width: 21vw;
    border-radius: 12px;
  }

  ${media.laptop} {
    flex: 0 0 27vw;
    max-width: 27vw;
  }
`;

export const CardTitle = styled.p`
  font-size: ${props => props.theme.heading3.fontSize};
  font-weight: 500;
  line-height: 1.2em;
  color: #233A60;
`;

export const Type = styled.p`
  margin: 5px 0;
  font-size: ${props => props.theme.heading5.fontSize};
  font-weight: 300;
  color: ${props => props.theme.colors.text.secondaryText};
`

export const Description = styled.p`
  height: 100px;
  margin: 0 0 5% 0;
`

export const CardContentWrapper = styled.div`
  display: block; 
  width: 100%;
  height: 9rem;

  & > div:first-of-type {
    margin: 0;
    font-family: "Libre Franklin";
    font-style: normal;
    color: ${props => props.theme.colors.text.generalText};
  }

  & > p:first-of-type {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    max-height: 10vh;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 2.5vh;
  }
`

export const CardContent = styled.p`
  margin: 0 0 6% 0;
  line-height: 1.2;
  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  overflow: hidden;
  display: -webkit-box !important;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  
  color: #6C7073;

  width: 100%;
`;

export const OtherTagsWrapper = styled.div`
  margin: 1.4vh 0 1vh;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;

  height: 5.5vh;
  overflow: auto;
`;

export const LocationWrapper = styled.div`
  width: fit-content;
  min-height: 5.5vh;
  display: inline-flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: flex-end;
`;

export const LearnMoreButton = styled(BoxedButton)`
  width: 70%;
  border-radius: 24px;
  margin: 0 auto;
  font-size: ${props => props.theme.heading4.fontSize};
  font-weight: 300;

  &:hover {
    cursor: pointer;
    color: #fafaf9;
    background-color: ${props => props.theme.colors.text.clickableText};
  }
`

export const SavedButton = styled.button`
  margin: "5px 0";
  width: 25%;

  background: none;
  padding: 8px;

  border: 1px solid #5095d5;
  border-radius: 14px;
  color: #5c2583;

  &:hover {
    cursor: pointer;
    color: #fafaf9;
    background-color: #5095d5;
  }
`;

export const CardPaginationWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: .5vw;
`

export const CardPageClick = styled.button`
  background: none;
	color: #584A9F;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
  font-size: ${props=> props.theme.heading2.fontSize};

  &:disabled{
      color: #C5C5C5;
      cursor: not-allowed;
  }
`